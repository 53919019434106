<template lang="pug">
v-app
  v-content
    v-container(fluid)
      v-layout(row, wrap)
        v-flex.xs12
          h1 Termos de Uso
      v-layout(row, wrap)
        v-flex.xs12
          h2 1. Termos
          p
            | Ao acessar ao site 
            a(href='http://auditoria.automatic4.labcinco.com/termos') Automatic4
            | , concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum                desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
          h2 2. Uso de Licença
          p
            | É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site Automatic4 , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e,                sob esta licença, você não pode:
          ol
            li modificar ou copiar os materiais;  
            li
              | usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);
            li
              | tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Automatic4;
            li
              | remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou
            li
              | transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.
          p
            | Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por Automatic4 a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais                baixados em sua posse, seja em formato eletrónico ou impresso.
          h2 3. Isenção de responsabilidade
          ol
            li
              | Os materiais no site da Automatic4 são fornecidos 'como estão'. Automatic4 não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização,            adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.
            li
              | Além disso, o Automatic4 não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos            materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.
          h2 4. Limitações
          p
            | Em nenhum caso o Automatic4 ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Automatic4,                mesmo que Automatic4 ou um representante autorizado da Automatic4 tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade                por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.
          h3 Precisão dos materiais
          p
            | Os materiais exibidos no site da Automatic4 podem incluir erros técnicos, tipográficos ou fotográficos. Automatic4 não garante que qualquer material em seu site seja preciso, completo ou atual. Automatic4 pode fazer alterações nos materiais contidos em seu                site a qualquer momento, sem aviso prévio. No entanto, Automatic4 não se compromete a atualizar os materiais.
          h2 6. Links
          p
            | O Automatic4 não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por Automatic4 do site. O uso de qualquer site vinculado é por conta e risco do usuário.
          p
          h3 Modificações
          p
            | O Automatic4 pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.
          h3 Lei aplicável
          p
            | Estes termos e condições são regidos e interpretados de acordo com as leis do Automatic4 e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.

</template>

<script>

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>
<style scoped lang="css">
</style>
